/* eslint-disable react/no-render-return-value */
/* eslint-disable prettier/prettier */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import MyBalance from "./Profile/MyBalance/MyBalance";
import PopupBuyNow from "./Common/LotView/PopupBuyNow"
import PaymentsLotsTable from "./Admin/TradeLot/PaymentsLotsTable"


const CategoryLotsList = React.lazy(() => import("./CategoryLotsList/List"));
const LotsEditor = React.lazy(() => import("./LotsEditor/LotsEditor"));
const MainSearch = React.lazy(() => import("./MainSearch/Index"));
const ProfileDetailsEditor = React.lazy(
  () => import("./Profile/Details/ProfileDetailsEditor"),
);
const VacationDetailsEditor = React.lazy(
  () => import("./Profile/Vacation/VacationDetailsEditor"),
);
const DeliveryAdressDetailsEditor = React.lazy(
  () => import("./Profile/DeliveryAdress/DeliveryAdressDetailsEditor"),
);
const LocationLotDetailsEditor = React.lazy(
  () => import("./Profile/LocationLot/LocationLotDetailsEditor"),
);
const BlackListSellersDetailsEditor = React.lazy(
  () => import("./Profile/BlackList/BlackListSellersDetailsEditor"),
);
const BlackListBuyersDetailsEditor = React.lazy(
  () => import("./Profile/BlackList/BlackListBuyersDetailsEditor"),
);
const StandartDescriptionLotEditor = React.lazy(
  () => import("./Profile/StandertDescriptionLot/StandartDescriptionLotEditor"),
);
const BalanceEditor = React.lazy(
  () => import("./Profile/Balance/BalanceEditor"),
);

const Appeal = React.lazy(() => import("./Profile/Appeal/Appeal"));

const Rate = React.lazy(() => import("./Profile/Rate/Rate"));

const PromotionServices = React.lazy(() => import("./Profile/PromotionServices/PromotionServices"));


const RatingLimit = React.lazy(() => import("./Profile/RatingLimit/RatingLimit"));


const OperatinosHistory = React.lazy(() => import("./Profile/OperatinosHistory/OperatinosHistory"));

const MessageToBuyers = React.lazy(() => import("./Profile/MessageToBuyers/MessageToBuyers"));

const Notifications = React.lazy(
  () => import("./Profile/Notifications/Components/Notifications"),
);

const AccountMessages = React.lazy(() => import("./Profile/Messages"));

const LotDocument = React.lazy(
    () => import("./Common/Document/LotDocument"),
);

const LotWinnerProtocol = React.lazy(
    () => import("./Common/Document/LotWinnerProtocol")
);

const ProfileDocumentsEditor = React.lazy(
  () => import("./Profile/Documents/ProfileDocumentsEditor"),
);
const DocumentsList = React.lazy(() => import("./Common/Document/List"));
const MyActivity = React.lazy(() => import("./MyActivity/MyActivity"));

const ToggleLotIsFavorite = React.lazy(
  () => import("./Common/ToggleLotIsFavorite/ToggleLotIsFavorite"),
);

const CloseToggleLotIsFavorite = React.lazy(
    () => import("./Common/ToggleLotIsFavorite/CloseToggleLotIsFavorite"),
);

const ChatBoxButton = React.lazy(() => import("./Common/Company/ChatBoxButton"));
const ChatMessages = React.lazy(() => import("./Profile/ChatMessages/ChatMessages"))

const LoginWithCert = React.lazy(() => import("./Common/LoginWithCert"));


import AccountInfo from "./Profile/AccountInfo";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Watermarks from "./Profile/Details/Watermarks";
import { IDocument } from "./Common/types";
import { IPurchasedService, ISelectListItem } from "./Common/types";

//const searchContainer = document.getElementById("search_list");
//if (searchContainer) {
//    ReactDOM.render(<React.Suspense fallback={<div className="loading show"></div>}>
//        <Search query={searchContainer.getAttribute('data-query')}
//            categoryId={Number(searchContainer.getAttribute('data-category-id'))} />
//    </React.Suspense>,
//        searchContainer);
//}

const accountInfo = document.getElementById("accountInfo");

if (accountInfo) {
    AccountInfo.Thumbprint = accountInfo.getAttribute('data-thumbprint');
    AccountInfo.Id = Number(accountInfo.getAttribute('data-id'));
    AccountInfo.Fio = accountInfo.getAttribute('data-fio');
    AccountInfo.CurrentUserCanSign = Boolean(accountInfo.getAttribute('data-currentUserCanSign'));
    AccountInfo.init();
    console.log('AccountInfo: ', AccountInfo);
}

const mainSearchContainer = document.getElementById("main_search");
if (mainSearchContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <MainSearch />
    </React.Suspense>,
    mainSearchContainer,
  );
}

const pageNotifications = document.getElementById("notifications");
if (pageNotifications) {
  ReactDOM.render(

    <React.Suspense fallback={<div className="loading show"></div>}>
      <Notifications />
    </React.Suspense>,
    pageNotifications,
  );
}

const pagePromotionServices = document.getElementById("promotion-services");
if (pagePromotionServices) {
  ReactDOM.render(

    <React.Suspense fallback={<div className="loading show"></div>}>
      <PromotionServices />
    </React.Suspense>,
    pagePromotionServices,
  );
}


const pageRatingLimit = document.getElementById("rating-limit");
if (pageRatingLimit) {
  ReactDOM.render(

    <React.Suspense fallback={<div className="loading show"></div>}>
      <RatingLimit />
    </React.Suspense>,
    pageRatingLimit,
  );
}



const pageOperatinosHistory = document.getElementById("operations-history");
if (pageOperatinosHistory) {
  ReactDOM.render(

    <React.Suspense fallback={<div className="loading show"></div>}>
      <OperatinosHistory />
    </React.Suspense>,
    pageOperatinosHistory,
  );
}


const MessageToBuyersContainer = document.getElementById("message-to-buyers");
if (MessageToBuyersContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <MessageToBuyers />
    </React.Suspense>,
    MessageToBuyersContainer,
  );
}

const categoryLotsListContainer = document.getElementById("category_lots_list");
if (categoryLotsListContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <CategoryLotsList
              categoryId={Number(
                  categoryLotsListContainer.getAttribute("data-category-id"),
              )}
              companyId={Number(
                  categoryLotsListContainer.getAttribute("data-company-id"),
              )}
              banner={categoryLotsListContainer.getAttribute("data-category-banner")}
              query={categoryLotsListContainer.getAttribute("data-query")}
              isBuyNow={
                  parseInt(categoryLotsListContainer.getAttribute("data-isBuyNow")) == 1
              }
              phraseType={parseInt(
                  categoryLotsListContainer.getAttribute("data-phraseType"),
              )}
              targetType={parseInt(
                  categoryLotsListContainer.getAttribute("data-targetType"),
              )}
              countryId={parseInt(categoryLotsListContainer.getAttribute("data-country"))}
              regionId={parseInt(
                  categoryLotsListContainer.getAttribute("data-region"),
              )}
              currentPage={parseInt(
                  categoryLotsListContainer.getAttribute("data-page"),
              )}
              sort={categoryLotsListContainer.getAttribute("data-sort")}
              tradeType={parseInt(
                  categoryLotsListContainer.getAttribute("data-tradeType"),
              )}
              timeSearch={parseInt(
                  categoryLotsListContainer.getAttribute("data-timeSearch"),
              )}
              mithraTheme={categoryLotsListContainer.getAttribute("data-mithra-theme")}
              startPrice={categoryLotsListContainer.getAttribute("data-start-price")}
              endPrice={categoryLotsListContainer.getAttribute("data-end-price")}
              startDate={categoryLotsListContainer.getAttribute("data-start-dat")}
              endDate={categoryLotsListContainer.getAttribute("data-end-date")}
              companyName={categoryLotsListContainer.getAttribute("data-company-name")}
              userName={categoryLotsListContainer.getAttribute("data-userName")}
        />
    </React.Suspense>,
    categoryLotsListContainer,
    );
}

const chatBoxContainer = document.getElementById("chat-box-container");
if(chatBoxContainer){
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"/>}>
            <ChatBoxButton
                currentAccountId={chatBoxContainer.getAttribute("data-current-user-id")}
                lotId={chatBoxContainer.getAttribute("data-lot-id")}
                sellerName={chatBoxContainer.getAttribute("data-seller-name")} 
                sellerAvatar={chatBoxContainer.getAttribute("data-seller-avatar")}/>
        </React.Suspense>,
        chatBoxContainer,
    );
}

const AgentHelper = React.lazy(() => import("./CategoryLotsList/AgentHelper/AgentHelper"));
const AgentHelperContainer = document.getElementById("AgentHelper");
if (AgentHelperContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show" />}>
            <AgentHelper userName={AgentHelperContainer.getAttribute("data-userName")} />
        </React.Suspense>,
        AgentHelperContainer,
    );
}

const AgentHelperMain = React.lazy(() => import("./CategoryLotsList/AgentHelper/AgentHelperMain"));
const AgentHelperMainContainer = document.getElementById("AgentHelperMain");
if (AgentHelperMainContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show" />}>
            <AgentHelperMain userName={AgentHelperMainContainer.getAttribute("data-userName")} />
        </React.Suspense>,
        AgentHelperMainContainer,
    );
}

const PreorderEquipment = React.lazy(() => import("./CategoryLotsList/PreorderEquipment/PreorderEquipment"));
const PreorderEquipmentContainer = document.getElementById("PreorderEquipment");
if (PreorderEquipmentContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show" />}>
            <PreorderEquipment userName={PreorderEquipmentContainer.getAttribute("data-userName")} />
        </React.Suspense>,
       PreorderEquipmentContainer,
    );
}

const chatMessagesContainer = document.getElementById("chat_messages");
if (chatMessagesContainer) {
    ReactDOM.render( 
        <React.Suspense fallback={<div className="loading show"></div>}>
            <ChatMessages   lotId={chatMessagesContainer.getAttribute("data-lot-id")}
                            currentAccountId={chatMessagesContainer.getAttribute("data-current-user-id")}
                            // sellerName={chatMessagesContainer.getAttribute("data-seller-name")}
                            sellerAvatar={chatMessagesContainer.getAttribute("data-seller-avatar")}/>
        </React.Suspense>,
        chatMessagesContainer,
    );
}

const lotEditorContainer = document.getElementById("lot_editor");
if (lotEditorContainer) {
  ReactDOM.render(
      <React.Suspense fallback={<div className="loading show"></div>}>
          <LotsEditor
              lotId={Number(lotEditorContainer.getAttribute("data-lot-id"))}
              validate={lotEditorContainer.getAttribute("data-validate") == "true"}
              isEdit={lotEditorContainer.getAttribute("data-is-edit") == "true"}
          />
      </React.Suspense>,
    lotEditorContainer
  );
}

const loginWithCert = document.getElementById("login_with_cert_container");
if (loginWithCert) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <LoginWithCert />
        </React.Suspense>,
        loginWithCert,
    );
}



const profileContainer = document.getElementById("profile_container");
if (profileContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <ProfileDetailsEditor />
    </React.Suspense>,
    profileContainer,
  );
}


let target = document.querySelector('header');
const config = {
  childList: true,
  subtree: true
};

const callback = function() {
  const watermarkContainer = document.getElementById('watermark-id')
  if (watermarkContainer) {
    ReactDOM.render(
      <React.Suspense fallback={<div className="loading show"></div>}>
        <Watermarks />
      </React.Suspense>,
      watermarkContainer,
    );
  }
};
const observer = new MutationObserver(callback);
observer.observe(target, config);




const rateContainer = document.getElementById("rate_container");
if (rateContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <Rate />
    </React.Suspense>,
    rateContainer,
  );
}

const appealContainer = document.getElementById("appeal_container");
if (appealContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <Appeal />
    </React.Suspense>,
    appealContainer,
  );
}


const lotDocumentContainer = document.getElementById(
    "lot_document_container",
);
if (lotDocumentContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <LotDocument lotId={Number(lotDocumentContainer.getAttribute("lotId"))} />
        </React.Suspense>,
        lotDocumentContainer,
    );
}

const lotDocumentsContainer = document.getElementById(
    "lot_documents_container",
);
if (lotDocumentsContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <DocumentsList loadUrl={"/TradeLotActionsApi/GetLotDocuments"} refresh={true} lotId={Number(lotDocumentsContainer.getAttribute("lotId"))} />
        </React.Suspense>,
        lotDocumentsContainer,
    );
}

const lotWinnerProtocolContainer = document.getElementById(
    "lot_winner_protocol_container",
);
if (lotWinnerProtocolContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <LotWinnerProtocol lotId={Number(lotWinnerProtocolContainer.getAttribute("lotId"))} />
        </React.Suspense>,
        lotWinnerProtocolContainer,
    );
}

const profileDocumentsContainer = document.getElementById(
  "profile_documents_container",
);
if (profileDocumentsContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <ProfileDocumentsEditor />
    </React.Suspense>,
    profileDocumentsContainer,
  );
}

const vacationContainer = document.getElementById("vacation_container");
if (vacationContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <VacationDetailsEditor />
    </React.Suspense>,
    vacationContainer,
  );
}

const deliveryAdressContainer = document.getElementById(
  "deliveri_adress_container",
);
if (deliveryAdressContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <DeliveryAdressDetailsEditor />
    </React.Suspense>,
    deliveryAdressContainer,
  );
}

const blackListSellersContainer = document.getElementById(
  "black_list_sellers_container",
);
if (blackListSellersContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <BlackListSellersDetailsEditor />
    </React.Suspense>,
    blackListSellersContainer,
  );
}
const blackListBuyersContainer = document.getElementById(
  "black_list_buyers_container",
);
if (blackListBuyersContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <BlackListBuyersDetailsEditor />
    </React.Suspense>,
    blackListBuyersContainer,
  );
}
const locationlotContainer = document.getElementById("location_lot_container");
if (locationlotContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <LocationLotDetailsEditor />
    </React.Suspense>,
    locationlotContainer,
  );
}
const standartDescriptionLotContainer = document.getElementById(
  "description_lot_container",
);
if (standartDescriptionLotContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <StandartDescriptionLotEditor />
    </React.Suspense>,
    standartDescriptionLotContainer,
  );
}

const balanceContainer = document.getElementById("balans_container");
if (balanceContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <BalanceEditor />
    </React.Suspense>,
    balanceContainer,
  );
}

const documentsListContainer = document.getElementById("documents_list");
if (documentsListContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
          <DocumentsList
        lotId={Number(documentsListContainer.getAttribute('data-lotId')) }
        loadUrl={documentsListContainer.getAttribute("data-load-url")}
        refresh={false}
      />
    </React.Suspense>,
    documentsListContainer,
  );
}

const myActivityContainer = document.getElementById("my_activity");
if (myActivityContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <BrowserRouter>
        <MyActivity />
      </BrowserRouter>
    </React.Suspense>,
    myActivityContainer,
  );
}

const toggleLotIsFavoriteContainers =
  document.getElementsByClassName("favourites-toggle");

if (toggleLotIsFavoriteContainers && toggleLotIsFavoriteContainers.length > 0) {
  [...toggleLotIsFavoriteContainers].map((container) =>
    ReactDOM.render(
      <React.Suspense fallback={<div></div>}>
        <ToggleLotIsFavorite
          lotId={Number(container.getAttribute("data-lotId"))}
          curUserFavorite={
            container.getAttribute("data-curUserFavorite").toLowerCase() ==
            "true"
          }
          userLoggedIn={
            container.getAttribute("data-userLoggedIn").toLowerCase() == "true"
          }
          showText={
            container.getAttribute("data-showText").toLowerCase() == "true"
          }
          type={container.getAttribute("data-fav-type")}
        />
      </React.Suspense>,
      container,
    ),
  );
}

const closeToggleLotIsFavoriteContainers =
    document.getElementsByClassName("cl-favourites-toggle");

if (closeToggleLotIsFavoriteContainers && closeToggleLotIsFavoriteContainers.length > 0) {
    [...closeToggleLotIsFavoriteContainers].map((container) =>
        ReactDOM.render(
            <React.Suspense fallback={<div></div>}>
                <CloseToggleLotIsFavorite
                    lotId={Number(container.getAttribute("data-lotId"))}
                    curUserFavorite={
                        container.getAttribute("data-curUserFavorite").toLowerCase() ==
                        "true"
                    }
                    userLoggedIn={
                        container.getAttribute("data-userLoggedIn").toLowerCase() == "true"
                    }
                    showText={
                        container.getAttribute("data-showText").toLowerCase() == "true"
                    }
                    type={container.getAttribute("data-fav-type")}
                />
            </React.Suspense>,
            container,
        ),
    );
}

const SCCloseRowItem = React.lazy(() => import("./Common/SCcloseRowItem/SCcloseRowItem"));
const SCCloseRowItemContainer = document.getElementById("SCcloseRowItem");
if (SCCloseRowItemContainer) {
    const username = SCCloseRowItemContainer.getAttribute("data-attribute-userName"); 
    const url_ = SCCloseRowItemContainer.getAttribute("data-attribute-URL_"); 
    console.log("User" + username);
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show" />}>
            <SCCloseRowItem userName={username} URL_={url_} />
        </React.Suspense>,
        SCCloseRowItemContainer,
    );
}

const myMessagesContainer = document.getElementById("my_messages");
if (myMessagesContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <AccountMessages />
    </React.Suspense>,
    myMessagesContainer,
  );
}

const myBalance = document.getElementById("myBalance");
if (myBalance) {
    let serv = myBalance.getAttribute("data-listServices");
    let purchServ = myBalance.getAttribute("data-purchasedServices");
    let purchLotApp = myBalance.getAttribute("data-purchasedLotApplicationPrice")
    let projectsDictionary = myBalance.getAttribute("data-projectsDictionary")
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <MyBalance
                Balance={Number(myBalance.getAttribute("data-amount"))}
                PurchasedLotApplicationPrices={JSON.parse(purchLotApp)}
                ListServices={JSON.parse(serv)}
                PurchasedServices={JSON.parse(purchServ)}
                CompanyId={Number(myBalance.getAttribute("data-companyId"))}
                Amount={Number(myBalance.getAttribute("data-amount"))}
                AccountId={Number(myBalance.getAttribute("data-accountId"))}
                ActiveServiceName={myBalance.getAttribute("data-activeServiceName")}
                ActiveOrderId={Number(myBalance.getAttribute("data-activeOrderId"))}
                ProjectsDictionary={JSON.parse(projectsDictionary)}
            />
        </React.Suspense>, myBalance
    );
}

const CloseSellArt = React.lazy(() => import("./Common/CloseSellArt/CloseSellArt"));
const CloseSellArtContainer = document.getElementById("CloseSellArt");
if (CloseSellArtContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show" />}>
            <CloseSellArt userName={""} />
        </React.Suspense>,
        CloseSellArtContainer,
    );
}

const popupBuyNowContainer = document.getElementById("popupBuyNow");
if (popupBuyNowContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <PopupBuyNow
                lotId={popupBuyNowContainer.getAttribute("data-lotId")}
                isTradeEnd={popupBuyNowContainer.getAttribute("data-isTradeEnd")}
            />
        </React.Suspense>,
        popupBuyNowContainer
    );
}

const dataTablePaymentsLotsContainer = document.getElementById("DataTablePaymentsLots");
if (dataTablePaymentsLotsContainer) {
    console.log('!!!!!234');
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <PaymentsLotsTable
                lotId={dataTablePaymentsLotsContainer.getAttribute("data-lotId")}
            />
        </React.Suspense>,
        dataTablePaymentsLotsContainer
    );
}

console.log("Main branch");

